import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../images/i-want-to-book-court.png";
import axios from "axios";
import "./header.scss";

const Header = (props) => {
  const [lastUpdate, setLastUpdate] = useState("")
  
  useEffect(() => {
    axios.get("https://us-central1-court-finder-37f55.cloudfunctions.net/widgets/get-last-update")
      .then((val) => {
        setLastUpdate(`最後更新: ${val.data.substring(4, 6)}/${val.data.substring(6, 8)} ${val.data.substring(8, 10)}:${val.data.substring(10, 12)}`)
      })
  }, [])
  return (
    <React.Fragment>
      <header>
        <h1>
          <img src={Logo} alt="" />
          <Link to="/">{props.siteTitle} <span>{lastUpdate}</span></Link>
        </h1>
      </header>
    </React.Fragment>
  )
}

export default Header
